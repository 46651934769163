import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl, AbstractControl, ValidatorFn, Validator, ValidationErrors, AbstractControlOptions } from '@angular/forms';
import { RtkdFormService } from '../../services/rtkd-form.service';

import { recaptchaSiteKeyConst } from '../../app.constants';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-rtkd-form',
  templateUrl: './rtkd-form.component.html',
  styleUrls: ['./rtkd-form.component.scss']
})
export class RtkdFormComponent implements OnInit {
  rtkdForm: FormGroup;
  requestToAccessForm: FormGroup;
  requestToKnowForm: FormGroup;
  requestToKnowDisclosuresForm: FormGroup;
  requestToDeleteForm: FormGroup;
  requestToCorrectForm: FormGroup;
  requestToDataPortabilityForm: FormGroup;
  requestToAppealForm: FormGroup;

  // Different sections of the form, all based on the requestType selected and the State of Residence
  showRequestToAccess: boolean = false;
  showRequestToKnowSection: boolean = false;
  showRequestToKnowDisclosuresSection: boolean = false;
  showRequestToDeleteSection: boolean = false;
  showRequestToCorrectSection: boolean = false;
  showRequestToDataPortabilitySection: boolean = false;
  showRequestToAppealSection: boolean = false;

  isDetailsValid: boolean = false;
  showSubmitButton: boolean = false;

  recaptchaSiteKey: string = recaptchaSiteKeyConst;
  displayrecaptchaMessage: boolean = true;

  authorizationFileName: string;
  authorizationFileError: string;

  phoneNumberPlaceholder = '';
  phoneNumberPlaceholderDefault = "Phone Number";
  addressPlaceholder = '';
  addressPlaceholderDefault = "Physical Address";
  requiredFlag = "*";

  submissionSuccessful = false;

  constructor(private formBuilder: FormBuilder, private rtkdFormService: RtkdFormService) {
    const formOptions: AbstractControlOptions = { validators: this.stateFormValidator };

    // Pre completed
    this.rtkdForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      middleName: [''],
      lastName: ['', Validators.required],
      state: ['', Validators.required],
      herebyCertify: [false, Validators.requiredTrue],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: [''],
      address: ['', Validators.required],
      accountNumber: [''],
      contactPreference: this.formBuilder.group({
        email: [false, Validators.required],
        phone: [false],
        letter: [false]
      }, { validators: this.atLeastOneRequiredValidator }),
      consentEmail: [false],
      requestTypes: this.formBuilder.group({
        access: [false],
        knowCategories: [false],
        knowDisclosures: [false],
        delete: [false],
        correct: [false],
        dataPortability: [false],
        appeal: [false]
      }, { validators: this.atLeastOneRequiredValidator}),
      userType: ['', Validators.required],
      otherUserType: [''],
      captchaResponse: ['', Validators.required],
      uploadAuthorization: ['']
    }, formOptions);

    // Subscribe to changes in the 'phone' checkbox value
    this.rtkdForm.get('contactPreference.phone').valueChanges.subscribe((value) => {
      if (value) {
        this.rtkdForm.get('phoneNumber').setValidators(Validators.required);
        this.phoneNumberPlaceholder = this.phoneNumberPlaceholderDefault + " " + this.requiredFlag;
      } else {
        this.rtkdForm.get('phoneNumber').clearValidators();
        this.phoneNumberPlaceholder = this.phoneNumberPlaceholderDefault;
      }
      // Trigger revalidation of the 'phoneNumber' control
      this.rtkdForm.get('phoneNumber').updateValueAndValidity();
    });

    this.phoneNumberPlaceholder = this.phoneNumberPlaceholderDefault;

    this.rtkdForm.get('state').valueChanges.subscribe((state: string) => {
      if (state === 'other') {
        this.rtkdForm.get('address').setValidators(Validators.required);
        this.addressPlaceholder = this.addressPlaceholderDefault + ' ' + this.requiredFlag;
      } else {
        this.rtkdForm.get('address').clearValidators();
        this.addressPlaceholder = this.addressPlaceholderDefault;
      }
      this.rtkdForm.get('address').updateValueAndValidity();
    });

    this.rtkdForm.get('contactPreference.letter').valueChanges.subscribe((value) => {
      if (value) {
        this.rtkdForm.get('address').setValidators(Validators.required);
        this.addressPlaceholder = this.addressPlaceholderDefault + ' ' + this.requiredFlag;
      } else {
        this.rtkdForm.get('address').clearValidators();
        this.addressPlaceholder = this.addressPlaceholderDefault;
      }
      this.rtkdForm.get('address').updateValueAndValidity();
    });

    this.addressPlaceholder = this.addressPlaceholderDefault;
  }

  ngOnInit() {
    // Initialize the form group for Request to Access section
    this.requestToAccessForm = this.formBuilder.group({
      specificPiecesDescription: [''],
      deliveryPaper: [false],
      deliveryElectronic: [false],
    });

    // Initialize the form group for Request to Know section
    this.requestToKnowForm = this.formBuilder.group({
      categoriesCollected: [false],
      categoriesSources: [false],
      purposeCollecting: [false],
      disclosedThirdParties: [false],
      specificPieces: [false],
      specificPiecesDescription: [''],
      deliveryPaper: [false],
      deliveryElectronic: [false],
    });

    // Initialize the form group for Request to Know Disclosures section
    this.requestToKnowDisclosuresForm = this.formBuilder.group({
      deliveryPaper: [false],
      deliveryElectronic: [false],
    });

    this.requestToDeleteForm = this.formBuilder.group({
      deleteAll: [false],
      deleteFinancial: [false],
      deleteBrowsing: [false],
      deleteCommercial: [false],
      deleteOther: [false],
      deleteOtherDescription: [''],
    });

    this.requestToCorrectForm = this.formBuilder.group({
      correctionDescription: [''],
    });

    this.requestToDataPortabilityForm = this.formBuilder.group({
      dataPortabilityAll: [false],
      dataPortabilityFinancial: [false],
      dataPortabilityBrowsing: [false],
      dataPortabilityCommercial: [false],
      dataPortabilityOther: [false],
      dataPortabilityOtherDescription: [''],
      deliveryPaper: [false],
      deliveryElectronic: [false],
    });

    this.requestToAppealForm = this.formBuilder.group({
      appealRequestType: ['', Validators.required],
      submittedDate: ['', Validators.required],
      responseDate: ['', Validators.required],
      appealDescription: ['', Validators.required],
      appealOutcome: ['', Validators.required],
    });
  }

  next(event): void {
    event.preventDefault();

    // Trigger form control validation and mark all fields as touched
    this.markAllFieldsAsTouched();

    if (this.rtkdForm.get('captchaResponse').valid) {
      this.displayrecaptchaMessage = true;
    } else {
      this.displayrecaptchaMessage = false;
    }

    if (this.rtkdForm.valid) {
      // Check if the form is valid up to the requestTypes field
      if (this.rtkdForm.get('requestTypes').valid) {
        const requestTypesArray = this.rtkdForm.get('requestTypes') as FormArray;

        // Show the Request to Access section if 'access' option is selected
        if (this.checkRequestTypeSelected(requestTypesArray, 'access')) {
          this.showRequestToAccess = true;
        } else {
          this.showRequestToAccess = false;
        }

        // Show the Request to Know section if 'know' option is selected
        if (this.checkRequestTypeSelected(requestTypesArray, 'knowCategories')) {
          this.showRequestToKnowSection = true;
        } else {
          this.showRequestToKnowSection = false;
        }

        // Show the Request to Know Disclosures section if 'know' option is selected
        if (this.checkRequestTypeSelected(requestTypesArray, 'knowDisclosures')) {
          this.showRequestToKnowDisclosuresSection = true;
        } else {
          this.showRequestToKnowDisclosuresSection = false;
        }

        // Show the Request to Delete section if 'delete' option is selected
        if (this.checkRequestTypeSelected(requestTypesArray, 'delete')) {
          this.showRequestToDeleteSection = true;
        } else {
          this.showRequestToDeleteSection = false;
        }

        // Show the Request to Correct section if 'correct' option is selected
        if (this.checkRequestTypeSelected(requestTypesArray, 'correct')) {
          this.showRequestToCorrectSection = true;
        } else {
          this.showRequestToCorrectSection = false;
        }

        // Show the Request to Data Portability section if 'dataPortability' option is selected
        if (this.checkRequestTypeSelected(requestTypesArray, 'dataPortability')) {
          this.showRequestToDataPortabilitySection = true;
        } else {
          this.showRequestToDataPortabilitySection = false;
        }

        // Show the Request to Appeal section if 'appeal' option is selected
        if (this.checkRequestTypeSelected(requestTypesArray, 'appeal')) {
          this.showRequestToAppealSection = true;
        } else {
          this.showRequestToAppealSection = false;
        }

        // Set the flag to show the Submit button
        this.showSubmitButton = true;
      }
    } else {
      // Scroll to the first invalid field
      this.scrollToFirstInvalidControl();
    }

    // Update the flag to indicate if the form is valid
    this.isDetailsValid = this.rtkdForm.valid;
  }

  scrollToFirstInvalidControl(): void {
    // Scroll to the first invalid field
    let form = document.getElementById('rtkdForm');
    let invalidControl = form.getElementsByClassName('ng-invalid')[0];
    if(invalidControl) {
      const header = document.querySelector('header');
      const headerOffset = header ? header.offsetHeight : 200; // Fallback to 100 if header is not found
      const elementPosition = invalidControl.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
      (invalidControl as HTMLElement).focus();
    }
  }

  // Validator function to check if at least one of the fields is selected
  atLeastOneRequiredValidator(formGroup: FormGroup): { [key: string]: any } | null {
    const controls = formGroup.controls;
    let hasAtLeastOne = false;

    for (const key in controls) {
      if (controls.hasOwnProperty(key)) {
        const control = controls[key];
        if (control.value) {
          hasAtLeastOne = true;
          break;
        }
      }
    }

    if (!hasAtLeastOne) {
      return { atLeastOneRequired: true };
    }

    return null;
  }

  stateFormValidator(formGroup: FormGroup): { [key: string]: any } | null {
    const stateControl = formGroup.get('state');

    if (stateControl.value === 'other') {
      return { invalidState: true };
    }

    return null;
  }

  stateUpdate(event): void {
    const stateValue = this.rtkdForm.get('state').value;

    const accessControl = this.rtkdForm.get('requestTypes.access');
    const knowCategoriesControl = this.rtkdForm.get('requestTypes.knowCategories');
    const knowDisclosuresControl = this.rtkdForm.get('requestTypes.knowDisclosures');
    const dataPortabilityControl = this.rtkdForm.get('requestTypes.dataPortability');
    const appealControl = this.rtkdForm.get('requestTypes.appeal');

    if (stateValue === 'CA') {
      accessControl.enable();

      knowCategoriesControl.disable();
      knowCategoriesControl.setValue(false);

      dataPortabilityControl.enable();
    } else {
      accessControl.disable();
      accessControl.setValue(false);

      knowCategoriesControl.enable();

      dataPortabilityControl.disable();
      dataPortabilityControl.setValue(false);
    }

    if(stateValue === 'CA' || stateValue === 'UT') {
      appealControl.enable();
    } else {
      appealControl.disable();
      appealControl.setValue(false);
    }

    if(stateValue === 'MD' || stateValue === 'OR') {
      knowDisclosuresControl.disable();
      knowDisclosuresControl.setValue(false);
    } else {
      knowDisclosuresControl.enable();
    }
  }

  markAllFieldsAsTouched(): void {
    Object.values(this.rtkdForm.controls).forEach(control => {
      control.markAsTouched();
    });
  }

  private checkRequestTypeSelected(requestTypesArray: FormArray, requestType: string): boolean {
    for (const key of Object.keys(requestTypesArray.controls)) {
      const control = requestTypesArray.controls[key] as FormControl;
      if (key === requestType && control.value === true) {
        return true;
      }
    }
    return false;
  }

  toggleRequestSection(requestType: string, checked: boolean): void {
    if (this.showSubmitButton) {
      switch (requestType) {
        case 'access':
          console.log('Access checkbox clicked', checked);
          if(this.rtkdForm.get('state').value === "CA") {
            this.showRequestToAccess = checked;
          }
          break;

        case 'knowCategories':
          console.log('Know Categories checkbox clicked', checked);
          if(this.rtkdForm.get('state').value === "CA") {
            this.showRequestToKnowSection = checked;
          }
          break;

        case 'knowDisclosures':
          console.log('Know Disclosures checkbox clicked', checked);
          if(this.rtkdForm.get('state').value === "MD" || this.rtkdForm.get('state').value === "OR") {
            this.showRequestToKnowDisclosuresSection = checked;
          }
          break;

        case 'delete':
          console.log('Delete checkbox clicked', checked);
          this.showRequestToDeleteSection = checked;
          break;

        case 'correct':
          console.log('Correct checkbox clicked', checked);
          this.showRequestToCorrectSection = checked;
          break;

        case 'dataPortability':
          console.log('Data Portability checkbox clicked', checked);
          this.showRequestToDataPortabilitySection = checked;
          break;

        case 'appeal':
          console.log('Appeal checkbox clicked', checked);
          if(this.rtkdForm.get('state').value === "CA" || this.rtkdForm.get('state').value === "UT") {
            this.showRequestToAppealSection = checked;
          }
          break;

        default:
          console.log('Invalid request type');
          break;
      }
    }
  }

  isInvalid(controlName: string): boolean {
    const control = this.rtkdForm.get(controlName);
    return control.invalid && (control.touched || control.dirty);
  }

  updateUserType(userType: string) {
    const otherUserTypeControl = this.rtkdForm.get('otherUserType');
    if (userType !== 'other') {
      otherUserTypeControl.setValue('');
    }
    this.rtkdForm.get('userType').setValue(userType);
  }

  onCaptchaResolved(response: string): void {
    this.rtkdForm.get('captchaResponse').setValue(response);
  }

  toggleSpecificPieces(event: MatCheckboxChange, formGroupIdentifier): void {
    console.log('Specific Pieces checkbox clicked', event);
    const checked = event.checked;
    if (!checked) {
      this.rtkdForm.get(formGroupIdentifier + 'specificPiecesDescription').reset();
    }
  }

  toggleDeleteOther(event: MatCheckboxChange): void {
    const deleteOtherControl = this.requestToDeleteForm.get('deleteOther');

    if (event.checked) {
      deleteOtherControl.setValue(true);
    } else {
      deleteOtherControl.setValue(false);
      this.rtkdForm.get('deleteOtherDescription').reset();
    }
  }

  toggleDataPortabilityOther(event: MatCheckboxChange): void {
    const dataPortabilityControl = this.requestToDataPortabilityForm.get('dataPortabilityOther');

    if (event.checked) {
      dataPortabilityControl.setValue(true);
    } else {
      dataPortabilityControl.setValue(false);
      this.rtkdForm.get('dataPortabilityOtherDescription').reset();
    }
  }

  handleFileUpload(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const file = inputElement.files[0];

    // Read the file content using FileReader or other methods
    const reader = new FileReader();

    // Check if the file is a PDF
    if (file.type !== 'application/pdf') {
      this.authorizationFileError = 'Please upload a PDF file';
      return;
    } else {
      this.authorizationFileError = '';
    }

    reader.onload = (e) => {
      // Encode the file content as base64 string
      const base64String = (reader.result as string).split(',')[1];

      // Set the encoded base64 string as the value of the form control
      this.rtkdForm.patchValue({ uploadAuthorization: "data:application/pdf;base64," + base64String });

      this.authorizationFileName = file.name;
    };
    reader.readAsDataURL(file);
  }

  submitForm(): void {
    // this.logData();
    const formData = {
      rtkdForm: this.rtkdForm.value,
      requestToAccessForm: this.requestToAccessForm.value,
      requestToKnowForm: this.requestToKnowForm.value,
      requestToKnowDisclosuresForm: this.requestToKnowDisclosuresForm.value,
      requestToDeleteForm: this.requestToDeleteForm.value,
      requestToCorrectForm: this.requestToCorrectForm.value,
      requestToDataPortabilityForm: this.requestToDataPortabilityForm.value,
      requestToAppealForm: this.requestToAppealForm.value
    };

    if (this.rtkdForm.valid) {
      // Call the service's submitForm method and pass the form data
      this.rtkdFormService.submitForm(formData).subscribe(
        response => {
          // Handle the response here
          // console.log('Form submission successful', response);
          // Reset the forms if needed
          this.rtkdForm.reset();
          this.requestToAccessForm.reset();
          this.requestToKnowForm.reset();
          this.requestToDeleteForm.reset();
          this.requestToCorrectForm.reset();
          this.requestToDataPortabilityForm.reset();
          this.requestToAppealForm.reset();

          // Update the submission status to true
          this.submissionSuccessful = true;
        },
        error => {
          // Handle the error here
          console.error('Error submitting form', error);
        }
      );
    } else {
      console.log('Form is invalid. Please fill in all required fields.');
    }
  }
}
